import React from "react"
import EventbriteButton from 'react-eventbrite-popup-checkout';

const Book_Your_Ticket = (props) => {

    const dataLayer = (item) =>{
        //console.log("event item", item)

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Book Your Ticket',
          'formType': "book_your_ticket",
          'formId': 'Book Your Ticket - '+item.EventId,
          'formName': 'Book Your Ticket',
          'formLabel': 'Book Your Ticket - '+item.Title
        });

    }

    return ( 
        <EventbriteButton ebEventId={props?.data?.EventId} isModal={true} className={props.className} onClick={() => dataLayer(props?.data)}>{props.children} {props.btnLabel}</EventbriteButton>
    )
}

export default Book_Your_Ticket;